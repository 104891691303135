<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >company Performance Overview</div>
                <a :href='`${API_ROOT}/schedule/company/${this.companyId}/performers/download`' target='_blank' class="highlight">
                  Download
                </a>
              </div>
            </div>
        </div>
      </div>
      
      
        
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem" style="max-width: 600px;">
        <h1>Codes</h1>
        <b-button @click="showAllCodes(true)" >
          Show All Codes
        </b-button>
        <b-button @click="showAllCodes(false)" >
          Hide All Codes
        </b-button>
                
        <b-table
            :data="codesData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableCodesIsLoading"
            :mobile-cards="true">
            
            <b-table-column field="codeId" label="code Id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.codeId }}
            </b-table-column>
            <b-table-column field="code" label="code" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.code }}
            </b-table-column>            
            <b-table-column field="name" label="name" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="scheduleId" label="schedule Id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.scheduleId }}
            </b-table-column>
            <b-table-column field="exclude_quotum" label="exclude quotum" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.exclude_quotum }}
            </b-table-column>
            <b-table-column label="show" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props">
                <b-button 
                  @click="toggleShowCode(props.row)"                  
                  :type="props.row.show === true ? 'is-success' : ''"
                  >
                  {{  props.row.show === true ? 'yes' : 'no' }}
                </b-button>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>      
        
      
      
      <div class="columns is-gapless is-multiline m2rem">
        <h1 class="column is-12">latest response_status:</h1>        
        <div class="column is-1" v-for="(status) in statusKeyList" v-bind:key="`${status.key}_index`">
          <b-button @click="toggleShowStatus(status)" :type="status.show === true ? 'is-success' : ''">
            {{  status.key }}
          </b-button>
        </div>
         
      </div>
      <div class="columns is-mobile is-gapless is-multiline m2rem">
        <h1 class="column is-12">count:</h1>
        <b-button @click="toggleShowTypeStatus('count')" :type="show.count ? 'is-success' : ''"> count </b-button>
         <b-button @click="toggleShowTypeStatus('hours')" :type="show.hours ? 'is-success' : ''"> hours </b-button>
         <b-button @click="toggleShowTypeStatus('quotemCount')" :type="show.quotemCount ? 'is-success' : ''"> quotemCount </b-button>
         <b-button @click="toggleShowTypeStatus('quotemHours')" :type="show.quotemHours ? 'is-success' : ''"> quotemHours </b-button>
      </div>
      <div class="columns is-mobile is-gapless is-multiline m2rem">
        <b-button @click="toggleShowOnlyWithQuotem" :type="filterQuotemGTZeroOn ? 'is-success' : ''"> Quotem > 0 Only</b-button>
      </div>
      
      <div class="columns is-mobile is-gapless is-multiline m2rem">
        <h5 class="column is-12">AsC = Assigned Count</h5>
        <h5 class="column is-12">AsH = Assigned Hours</h5>
        <h5 class="column is-12">QAvC = Quatem Assigned Count</h5>
        <h5 class="column is-12">QAvH = Quatem Assigned Hours</h5>
      </div>
      
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Performers</h1>
        <b-select v-model="performanceDataIsPaginatedPerPage">
            <option value="50">50 per page</option>
            <option value="250">250 per page</option>
            <option value="500">500 per page</option>
            <option value="2000">2000 per page</option>
            <option value="10000">10000 per page</option>
        </b-select>
        <b-table
            :data="performanceDataFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tablePerformanceIsLoading"
            :mobile-cards="true"
            :paginated="performanceDataIsPaginated"
            :per-page="performanceDataIsPaginatedPerPage"
            :current-page.sync="performanceDataCurrentPage"
            :columns="columnData">
            
        </b-table>
      </div>      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
      
      
    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyId           : null,
          companyData         : [],
          performanceData     : [],
          performanceDataFiltered     : [],
          columnData          : [],
          tablePerformanceIsLoading : false,
          codesData           : [],
          tableCodesIsLoading : false,
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          
          performanceDataIsPaginated: true,
          performanceDataIsPaginatedPerPage:250,
          performanceDataCurrentPage: 1,
          
          statusKeyList     : [
            {"id":"6","key":"approved", show: false},  
            {"id":"2","key":"assigned", show: true},
            {"id":"1","key":"available", show: false},
            {"id":"5","key":"cancelled", show: false},  
            {"id":"4","key":"confirmed", show: false},
            {"id":"3","key":"declined", show: false},
            {"id":"7","key":"delegated", show: true},
            {"id":"9","key":"notified", show: false},
            {"id":"8","key":"suggest", show: false}
          ],
          show : {
            count : true,
            hours : true,
            quotemCount : false,
            quotemHours : false
          },
          filterQuotemGTZeroOn : true,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.ShowPageLoading(15000)
        this.getCompanyData()
      },
      methods: {
        async getCompanyData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/performers`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          console.info(`response:`, response)
          this.companyData      = response.data.payload.companyData
          this.performanceData  = response.data.payload.performersData
          this.codesData        = response.data.payload.codes
          this.HidePageLoading()
          this.filterPerformanceData()
        },
        showStatusKey(keyName) {          
          for(let key in this.statusKeyList) {
            if (this.statusKeyList[key].key !== keyName) continue
            
            if (this.statusKeyList[key].show === true) {
              return true;
            } 
            return false
          }
          return false
        },
        filterPerformanceData() {
          let list = []
          for(let key in this.performanceData) {
            let row = this.performanceData[key]
            
            if (this.filterQuotemGTZeroOn && row.quotum <=0) continue
            
            for(let status of this.statusKeyList) {
              if (status.show !== true) continue
              row[`${status.key}_count`] = 0
              row[`${status.key}_hours`] = 0
            }
            
            for(let code of this.codesData) {
              row[`${code.codeId}-${code.code}_assignedC`] = 0
              row[`${code.codeId}-${code.code}_assignedH`] = 0
              row[`${code.codeId}-${code.code}_availableC`] = 0
              row[`${code.codeId}-${code.code}_availableH`] = 0              
            }
            
            for(let statKey in row.stats) {
              if (typeof(row.stats[statKey].count) !== "undefined") {                
                row[`${statKey}_count`] = row.stats[statKey].count
                row[`${statKey}_hours`] = row.stats[statKey].seconds / 3600
              } else {
                row[`${statKey}_assignedC`] = row.stats[statKey].assignedC
                row[`${statKey}_assignedH`] = row.stats[statKey].assignedH / 3600
                row[`${statKey}_availableC`] = row.stats[statKey].availableC
                row[`${statKey}_availableH`] = row.stats[statKey].availableH / 3600
              }
            }
            row.user_id_HREF = `<a href='${this.WEB_ROOT}/company/${this.companyId}/user/${row.user_id}' target='_blank'>details</a> `
            
            list.push(row)
          }
          
          
          let columnData = [
            { field: 'company_id', label: 'company id', width: 40, numeric: true, searchable: true, centered: true, sortable: true },
            { field: 'user_id', label: 'user id', width: 40, numeric: true, searchable: true, centered: true, sortable: true },
            { field: 'user_id_HREF', label: 'details', width: 40, centered: true},
            { field: 'users_name', label: 'name', width: 100, searchable: true, centered: false, sortable: true },
            { field: 'users_email', label: 'email', width: 100, searchable: true, centered: false, sortable: true },
            { field: 'profileName', label: 'profile name', width: 100, searchable: true, centered: false, sortable: true },
            { field: 'rolesSrt', label: 'roles', width: 100, searchable: true, centered: false, sortable: true },
            { field: 'min_hours', label: 'min hours', width: 40, numeric: true, searchable: true, centered: true, sortable: true },
            { field: 'max_hours', label: 'max hours', width: 40, numeric: true, searchable: true, centered: true, sortable: true },
            { field: 'quotum', label: 'quotum', width: 40, numeric: true, searchable: true, centered: true, sortable: true },
            { field: 'score', label: 'score', width: 40, numeric: true, searchable: true, centered: true, sortable: true },
          ]
          
          for(let status of this.statusKeyList) {
            if (status.show !== true) continue
            
            if (this.show.count) columnData.push( { field: `${status.key}_count`, label: `${status.key} C`, width: 10, numeric: true, searchable: true, centered: true, sortable: true } )
            if (this.show.hours) columnData.push( { field: `${status.key}_hours`, label: `${status.key} H`, width: 10, numeric: true, searchable: true, centered: true, sortable: true } )
            if (this.show.quotemCount) columnData.push( { field: `${status.key}_Quotem_count`, label: `${status.key} QC`, width: 10, numeric: true, searchable: true, centered: true, sortable: true } )
            if (this.show.quotemHours) columnData.push( { field: `${status.key}_Quotem_hours`, label: `${status.key} QH`, width: 10, numeric: true, searchable: true, centered: true, sortable: true } )
          }
          
          for(let code of this.codesData) {
            if (code.show !== true) continue
            
            if (this.show.count) columnData.push( { field: `${code.codeId}-${code.code}_assignedC`, label: `${code.codeId}-${code.code} AsC`, width: 10, numeric: true, searchable: true, centered: true, sortable: true })
            if (this.show.hours) columnData.push( { field: `${code.codeId}-${code.code}_assignedH`, label: `${code.codeId}-${code.code} AsH`, width: 10, numeric: true, searchable: true, centered: true, sortable: true })
            if (this.show.quotemCount) columnData.push( { field: `${code.codeId}-${code.code}_availableC`, label: `${code.codeId}-${code.code} QAvC`, width: 10, numeric: true, searchable: true, centered: true, sortable: true })
            if (this.show.quotemHours) columnData.push( { field: `${code.codeId}-${code.code}_availableH`, label: `${code.codeId}-${code.code} QAvH`, width: 10, numeric: true, searchable: true, centered: true, sortable: true })
            
          }
          
          this.columnData = columnData
          console.info(`columnData:`, columnData)
          
          this.performanceDataCurrentPage = 1
          
          this.performanceDataFiltered = list
          console.info(`this.performanceDataFiltered:`, this.performanceDataFiltered)
        },
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        showAllCodes(showCode){
          for(let code of this.codesData) {
            code.show = showCode
          }
          this.filterPerformanceData()
        },
        toggleShowCode(code){
          console.info(`toggleShowCode`, code)
          code.show = !code.show
          this.filterPerformanceData()
        },
        toggleShowStatus(status){
          console.info(`toggleShowStatus`, status)
          status.show = !status.show
          this.filterPerformanceData()
        },
        toggleShowTypeStatus(keyName){
          console.info(`toggleShowTypeStatus ${keyName}`, this.show[keyName])
          this.show[keyName] = !this.show[keyName]
          this.filterPerformanceData()
        },
        toggleShowOnlyWithQuotem(){
          this.filterQuotemGTZeroOn = !this.filterQuotemGTZeroOn
          this.filterPerformanceData()
        },
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
      }
    }
</script>

<style scoped>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
</style>